@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    @apply flex min-h-full justify-center scroll-smooth;
  }

  body {
    @apply min-h-full w-full font-azeret antialiased lg:flex lg:flex-col lg:items-center;
  }

  a {
    @apply hover:underline;
  }

  * {
    @apply motion-reduce:!animate-none motion-reduce:!transition-none;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
